
button {
  width: 30px;
  background: none;
  border: none;
  cursor: pointer;
}


.languageChooserContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-right: 20px;
  border: 2px #8a8585 solid;
  border-radius: 5px;
  height: 15px;
  width: 60px;

}

.languageChooser_MobileContainer{
  display: none;
}

.deButton,
.enButton {
  font-size: 0.7em;
  transition: 0.25s;
  color: #8a8585;
  padding-top: 1px;
}


.deButtonClicked, 
.enButtonClicked {
  font-size: 0.7em;
  background: #8a8585;
  color: white;
  //border: 4px #8a8585 solid;
  transition: 0.25s;
  padding-top: 1px;
}


.deButton:hover, 
.enButton:hover {
 // background-color: #009fe3;
  //border: 4px #009fe3;
  color: #bc434f;
}

@media (max-width: 730px) {
  .languageChooserContainer{
   //display: none;
    position: absolute;
    top: 1.5em;
    right: 5em;
  }
  //.languageChooser_MobileContainer{
  //  display: flex;
  //  flex-direction: row;
  //  width: 15%;
  //  margin: 1em auto;
  //  border: 2px #8a8585 solid;
  //  border-radius: 5px;
  //  height: 15px;
  //}
}