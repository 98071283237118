$color: #565656;

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;}

#footer {
  margin-top: auto;
}
* {
    font-family: "Normal";
}


a {
    text-decoration: none;
    color: $color;
}

a:hover {
    color: #bc434f;
    background-color: transparent;
    text-decoration: underline #bc434f 2px;
}

h1 {
    //font-family: Bold;
    margin-bottom: 2em;
    font-size: 2.5em;
    color: #333333;
    //text-align: center;

}

h2 {
    //font-family: Bold;
    margin-bottom: 2em;
    font-size: 2.8rem;
    color: #333333;
    text-align: center;
}

li,
ol,
ul {
    color: $color;
}

li ::marker {
    color: $color;
}

h3 {
    font-family: Bold;
    font-size: 1.4em;
    color: $color;
}

p {
    font-size: 1.3em;
    //text-align: left;
    color: $color;
}

.imgBannerContainer{
    position: relative;
    text-align: center;
    width: 99%;
}

.imageHeader{
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    margin: auto;
}

.imageHeader h1 {
    color: white;
    text-shadow: 5px 5px #4e4e4e;
    margin: 5em auto auto auto;
    //margin-top: 5em;
    width: 80%;
}

.cookieButton{
    width: 80% !important;
    margin-right: 2em;

}

//
.underline {
    border-bottom: 2px solid black;
    width: 35em;
}
//
.underlineRightSide {
    border-bottom: 2px solid black;
    width: 35em;
    margin-left: auto;
    margin-right: 11%;
}

.underlineCenter {
    border-bottom: 2px solid black;
    width: 35em;
    margin: -5em auto 4em auto;
}

.underlineCenterLong {
    border-bottom: 2px solid black;
    width: 30em;
    margin: -3.3em auto 4em auto;
}

.imageBanner {
  width: calc(100% - 2em); /* Subtract 2em (1em margin on both sides) from 100% width to get even spacing when 1em left margin is added */

  margin: 5.5em auto -8em auto;
  position: relative;
  background-image: url("https://blacktusk.eu/pictures/Webside/banner_main_clean_4x.png") ;
  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  text-align: center;
  height: 60vh;

  @media (max-width: 1400px) {
    .imageHeader h1 {
      margin: auto auto 1em auto !important;

    }
  }

  @media (max-width: 1200px) {
    .imageHeader h1 {
      margin: 5em auto auto auto;
      width: 70%;
    }
    margin: 5em auto -13em auto;
  }


  @media (max-width: 1000px) {
    .imageHeader h1 {
      margin: 60px auto 4em auto !important;
      font-size: 28px;
    }
  }

  @media (max-width: 600px) {
    background-image: url("https://blacktusk.eu/pictures/Webside/banner_main_clean_4x_mobile.png") !important;
    width: 98%;
    background-attachment: scroll;
    margin-top: 85px;
    margin-bottom: 0em;
    height: 25vh;

    p {
      font-size: 1.3em;
      color: $color;
    }
  }
}

@font-face {
  font-family: "Normal";
  src: local("D-DIN"), url("./fonts/D-DIN.woff") format("woff");
}

@font-face {
  font-family: "Bold";
  src: local("D-DIN-Bold"), url("./fonts/D-DIN-Bold.woff") format("woff");
}

@font-face {
  font-family: "Condensed";
  src: local("D-DINCondensed"), url("./fonts/D-DINCondensed.woff") format("woff");
}

@font-face {
  font-family: "Condensed-Bold";
  src: local("D-DINCondensed-Bold"), url("./fonts/D-DINCondensed-Bold.woff") format("woff");
}


@media screen and (max-width: 1200px) {

  //.imageHeader h2 {
  //    margin-top: 2em;
  //    font-size: 18pt !important;
  //}
}

@media screen and (max-width: 1000px) {

  //.imageHeader h2 {
  //    margin-top: 4em;
  //    font-size: 16pt !important;
  //}
}

@media screen and (max-width: 750px) {

        .imageHeader h1 {
            margin-top: 7em;
            width: 100%;
        }
    }
    

@media screen and (max-width: 600px) {

  .imageHeader {
    width: 90%;
  }

    .imageHeader h1 {
        margin-top: -1em;
        font-size: 12pt !important;
        width: 80%;
      text-shadow: none;
    }

    h1, h2 {
        font-size: 24px;
        //text-align: left;
        margin-bottom: 0.5em;
    }
    p {
        font-size: 16px;
        //text-align: left;
        //padding: 3em;
        //max-width: 90%;
    }

    .underline {
        margin-top:- 0.1em;
        width: 17em;

    }

    .underlineRightSide {
        margin-top: 0.1em;
        margin-right: auto;
        margin-left: auto;
        width: 15em;
    }

    .underlineCenter {
        margin-top: 0.1em;
        width: 80%;
    }

    .underlineCenterLong {
        margin-top: 0.1em;
        width: 20em;
    }
 }
