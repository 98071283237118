.footerContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px ;
  background-color: rgba(220, 220, 220, 0.8);
}

.languageChooser {
  display: none;
}

.navbarEmailIconLink {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1.5em;
  text-align: center;
}

.navbarEmailIconLink img {
  width: 50%;
}

.linktsToSides {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1.5em;

}

.linktsToSides a {
  margin-left: 1em;
}

.followUs {
  width: 220px;
  display: flex;
  align-items: center;
  //justify-content: space-evenly;
}

.followUs span {
  color: #565656;
}

.linkToImpressum{
  display: flex;
  align-items: center;
  justify-content: center;
}

.scrollToTop{
  display: flex;
  align-items: center;
  justify-content: center;
  color: #565656;
}

.toTopButton {
  color: #565656;

  @media (max-width: 600px) {
    width: 90%;
    font-size: 1.5em;
    padding: 1em;
  }
}

.toPageIcon {
  display: none;

  @media (max-width: 600px) {
    display: inline-block;
   width: 15%;
    margin-left: 0.5em;
  }
}

.linksToSideContent {
  margin: 1em;

  @media (max-width: 600px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

  }
}

.followUsIcons {
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 10%;

}


//.socialMediaIcon {
//  margin: auto 1em;
//
//}
//
//.xingIcon {
//  margin: auto;
//}

.socialMediaIcon {
  width: 30px;
  margin: 1em 0.5em;
  //background-color: red;
}


@media (max-width: 600px) {

  .footerContainer {
    flex-direction: column;
    justify-content: space-evenly;
    padding: 20px;
  }

  .footerContainer a {
    font-size: 1em ;
  }

  .btLogo{
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }

  .linktsToSides {
    margin: 2em auto;
    flex-direction: column;
  }


  .linktsToSides a {
    text-align: center;
    margin: auto;
    padding: 2em;

  }

  .navbarEmailIconLink{
    width: 30%;
    margin: 1em auto;
  }

  .followUs {
    flex-direction: column;
    margin: auto;
  }

  .followUs span {
    margin-left: auto;
    margin-right: auto;
  }

  .followUs a {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .followUsIcons {
    display: flex;
    flex-direction: column;
    width: 20%;
    justify-content: center;
    margin: 1em auto;
    text-align: center;
  }

  .socialMediaIcon {
    width: 70%;
  }



}

