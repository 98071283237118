.navBarContainer {
    background-color: rgba(220, 220, 220, 0.97);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
}

a {
    cursor: pointer;
}

.navbarLogoLink{
    display: flex;
    //align-items: center;
    margin: 20px;
}

.webNavigationMain {
    display: flex;
    //align-items: center;
    margin-left: auto;
    //margin-right: 40px;
}

.webNavigation {
//ph
}

.navbarLogo {
    width: 15em;
}

.navContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-decoration: none;
    list-style-type: none;
    //width: 100%;
}

.navChild{
    //padding-right: 3em;
    font-size: 1.2em;
    font-weight: bold;
    color: #565656;
    margin-right: 20px;;
    //margin-right: 20px;
}

//.navbarEmailIconLink{
//    margin-right: 20px;
//    width: 3em;
//    height: 1.8em;
//}

.navLinks{
//placeholder
}

a:hover{
    //color:#faf7f7 ;
    //background: #009fe3;
    //border-radius: 2px;
    //transition: 0.1s;
}

.mobileNavigation {
    display: none;
    overflow: hidden;
}

.dropdown {
    display: flex;
    flex-direction: column;
    position: absolute;
    margin-left: -3.8em;
}
//.menu {
//    position: absolute;
//    background-color: #f9f9f9;
//    min-width: 160px;
//    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
//    transition: all 1s ease-in;
//    overflow:hidden;
//    height:0;
//}


.dropdown-content {
    position: absolute;
    margin-top: 1.5em;
    background-color: rgba(223, 223, 223, 0.9);
    transition: all 0.3s ease-in;
    overflow:hidden;
    height:0;
    width: 200%;
    box-shadow: 0px 0px 10px 0px #4e4e4e;
}

.dropdown-content a {
    padding:0.5em 0.1em ;
    text-decoration: none;
    display: block;
    border-right: #dddddd 2px solid;
    border-left: #dddddd 2px solid;
}

.secondLink {
    border-bottom: #dddddd 5px solid;
}

.dropdown-content a:hover {
    text-decoration: underline 2px;
}

.dropdown:hover .dropdown-content {
    height:400%;
    width: 200%;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
//.dropdown:hover .dropbtn {
//    background-color: #3e8e41;
//    color:  #000000;
//    transition: 0.3s;
//}

//.menu a {
//    color: black;
//    padding: 12px 16px;
//    text-decoration: none;
//    display: block;
//}


.menu a:hover {
    background-color: #dddddd !important;
    color:  #000000;
}

@media (max-width: 1000px) {
    .navbarLogo {
        width: 10em;
    }
    .navBarContainer {
        font-size: 10pt;
    }
    .navContainer {
        padding:0;
    }

}


@media (max-width: 600px) {

    .navBarContainer a {
        font-size: 1em;
    }
    .webNavigation {
        display: none;
    }
    .productsNavLinks {
        display: flex;
    }

    .mobileNavigation {
        display: flex;
        flex-direction: column;
        width: 80%;
        overflow: hidden;
    }
    .mobileNavigation .hamburger {
        margin-right: 2em;
        //right: 5%;
        //top: 6%;

    }
    .mobileNavigation ul {
        background-color: rgba(223, 223, 223, 0.95);
        list-style-type: none;
        position: absolute;
        width: 100%;
        top: 30px;
        right: 0em;
        //border-top: 2px solid #565656;
        text-align: center;

}
    .mobileNavigation  li {
        width: 100%;
        padding-top: 1em;
        padding-bottom: 1em;
    }

    .navBarContainer {
        top: 0em;
    }
    .navbarLogo {
        width: 50%;
        margin-top: 0;
    }


}
